<template>
  <div class="home">
    <div>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="5000"
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="800"
              :src="require('../../assets/1.jpg')"
              alt="image slot"
            /> </template
        ></b-carousel-slide>

        <!-- Slides with custom text -->
        <!-- <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="800"
              :src="require('../../assets/2.jpg')"
              alt="image slot"
            />
          </template>
        </b-carousel-slide> -->

        <!-- Slides with image only -->
        <b-carousel-slide>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="1024"
              height="800"
              :src="require('../../assets/3.jpg')"
              alt="image slot"
            /> </template
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div class="title wow fadeInUp">
      <div style="width: 100%; height: 25px"></div>
      自主研发/制造
      <!-- <p
        class="wow fadeInUp"
        style="font-size: 14px; color: #aaaaaa; margin-top: 10px"
      >
        自主研发/制造
      </p> -->
      <div style="width: 100%; height: 20px"></div>
    </div>
    <div class="product_bigbox">
      <!-- <div class="title2 wow fadeInUp">
        <div style="width: 100%; height: 55px"></div>
        <b-img
          :src="require('../../assets/fs.png')"
          fluid
          alt="Responsive image"
        ></b-img>
        <div style="width: 100%; height: 20px"></div>
      </div> -->
      <div class="product_box">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="多设备站点地图可视化大屏"
                :img-src="require('../../assets/product/xk_mce.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="精准加药控制系统"
                :img-src="require('../../assets/product/xk_process.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="VOC气体处理监测控制可视化大屏"
                :img-src="require('../../assets/product/xk_jd.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="设备运行状态监测控制可视化大屏"
                :img-src="require('../../assets/product/xk_xdy.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="三维可视化运维监控大屏"
                :img-src="require('../../assets/product/xk_xdy_three.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="园区水平衡运营监控大屏"
                :img-src="require('../../assets/product/gongye.png')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="3D动态可视化大屏"
                :img-src="require('../../assets/product/xk_three.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="工业园区智慧运营管理"
                :img-src="require('../../assets/product/xk_yq.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <!-- <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="大型工业设备运行监控"
                :img-src="require('../../assets/product/xk_gy.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col> -->
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="水污染监测管理"
                :img-src="require('../../assets/product/xk_swr.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
          </b-row>
        </b-container>
      </div>
      <!-- <div class="title2 wow fadeInUp">
        <div style="width: 100%; height: 25px"></div>
        <b-img
          :src="require('../../assets/jz.png')"
          fluid
          alt="Responsive image"
        ></b-img>
        <div style="width: 100%; height: 20px"></div>
      </div> -->
      <!-- <div class="product_box">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="三维可视化运维监控大屏"
                :img-src="require('../../assets/product/xk_xdy_three.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="VOC气体处理监测控制可视化大屏"
                :img-src="require('../../assets/product/xk_jd.gif')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="精准加药控制系统"
                :img-src="require('../../assets/product/xk_process.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col>
          </b-row>
        </b-container>
      </div> -->
      <!-- <div class="title2 wow fadeInUp">
        <div style="width: 100%; height: 25px"></div>
        <b-img
          :src="require('../../assets/zy.png')"
          fluid
          alt="Responsive image"
        ></b-img>
        <div style="width: 100%; height: 20px"></div>
      </div> -->
      <!-- <div class="product_box">
        <b-container class="bv-example-row" fluid>
          <b-row>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="工业园区智慧监测"
                :img-src="require('../../assets/product/xk_yq.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="大型工业设备运行监控"
                :img-src="require('../../assets/product/xk_gy.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="水污染监测管理"
                :img-src="require('../../assets/product/xk_swr.jpg')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col> -->
            <!-- <b-col class="wow fadeInUp" lg="4" md="6" sm="6" xs="auto"
              ><b-card
                title="智能物联PLC"
                img-alt="Image"
                :img-src="require('../../assets/product/index3.jpg')"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
                style="text-align: right"
              >
              </b-card
            ></b-col> -->
            <!-- <b-col class="wow fadeInUp" lg="3" md="6" sm="6" xs="auto"
              ><b-card
                title="KST-512C智能物联PLC"
                :img-src="require('../../assets/product/PLC-1.png')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="3" md="6" sm="6" xs="auto"
              ><b-card
                title="KST-312C智能物联PLC"
                :img-src="require('../../assets/product/PLC-2.png')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col>
            <b-col class="wow fadeInUp" lg="3" md="6" sm="6" xs="auto"
              ><b-card
                title="KST-011C智能物联PLC"
                :img-src="require('../../assets/product/PLC-3.png')"
                img-alt="Image"
                img-top
                tag="article"
                title-tag="h5"
                class="mb-4 card_li"
              >
              </b-card
            ></b-col> -->
          <!-- </b-row>
        </b-container>
      </div> -->
    </div>

    <div class="title wow fadeInUp">
      <div style="width: 100%; height: 25px"></div>
      合作
      <p
        class="wow fadeInUp"
        style="font-size: 14px; color: #aaaaaa; margin-top: 10px"
      >
        服务了哪些客户很重要 用心 更重要
      </p>
      <div style="width: 100%; height: 20px"></div>
    </div>
    <div class="cooperation_box">
      <b-container class="bv-example-row" fluid>
        <b-row
          class="wow fadeInUp"
          cols="1"
          cols-sm="5"
          cols-md="5"
          cols-lg="5"
        >
          <b-col class="mb-4" style="text-align: center"
            ><b-img
              class="cooperation_li"
              :src="require('../../assets/xdy.png')"
              fluid
              alt="Fluid image"
            ></b-img
          ></b-col>
          <b-col class="mb-4" style="text-align: center"
            ><b-img
              class="cooperation_li"
              :src="require('../../assets/yk.png')"
              fluid
              alt="Fluid image"
            ></b-img
          ></b-col>
          <b-col class="mb-4" style="text-align: center"
            ><b-img
              class="cooperation_li"
              :src="require('../../assets/lvxin.png')"
              fluid
              alt="Fluid image"
            ></b-img
          ></b-col>
          <b-col class="mb-4" style="text-align: center"
            ><b-img
              class="cooperation_li"
              :src="require('../../assets/jinda.png')"
              fluid
              alt="Fluid image"
            ></b-img
          ></b-col>
          <b-col class="mb-4" style="text-align: center"
            ><b-img
              class="cooperation_li"
              :src="require('../../assets/ziyuan.png')"
              fluid
              alt="Fluid image"
            ></b-img
          ></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="title wow fadeInUp">
      <div style="width: 100%; height: 25px"></div>
      问答
      <!-- <p
        class="wow fadeInUp"
        style="font-size: 14px; color: #aaaaaa; margin-top: 10px"
      >
        xxxxx
      </p> -->
      <div style="width: 100%; height: 20px"></div>
    </div>
    <div class="problem_box">
      <b-container class="bv-example-row" fluid>
        <b-row
          class="wow fadeInUp"
          cols="1"
          cols-sm="2"
          cols-md="2"
          cols-lg="2"
        >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title="贵公司系统有什么优势?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：本公司系统集软硬件一体，可对远程对现场状况进行查看，并且通过PLC实现远程精准控制。
              </b-card-text>
            </b-card></b-col
          >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title="系统如何实现远程控制现场设备?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：现场PLC传输现场设备状态至云端系统，操作人员通过系统内操作下发命令至PLC实现远程控制。
              </b-card-text>
            </b-card></b-col
          >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title="如何保证数据库数据安全?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：我们会定期对数据库进行备份，数据库账号分级，对存储数据进行加密
              </b-card-text>
            </b-card></b-col
          >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title="贵公司的PLC相比与传统PLC有什么优势?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：与传统PLC相比, 本公司PLC自带4G全网通的网关,
                可以轻松对接阿里云平台, 快速完成设备的云服务
              </b-card-text>
            </b-card></b-col
          >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title="不更换PLC硬件设备能否快速接入云端运维管理平台?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：依托MCGS
                4G网关HMI的强大端口与底层驱动可快速对接设备的现场PLC,实现设备的远程监控
              </b-card-text>
            </b-card></b-col
          >
          <b-col class="mb-4"
            ><b-card
              style="border: 0; border-radius: 0; border-bottom: 1px solid #ccc"
              title=" 如果使用贵公司的PLC产品, 是否支持远程OTA升级?"
              sub-title="2021-12"
            >
              <b-card-text>
                答：完全可以, 且升级过程中不影响现场设备的逻辑控制功能
              </b-card-text>
            </b-card></b-col
          >
        </b-row>
      </b-container>
      <div style="width: 100%; height: 100px"></div>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  metaInfo() {
    return {
      title: "小鲲智能技术(广州)有限公司",
      meta: [
        {
          name: "keywords",
          content: "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,环保,物联网,智能物联PLC,精准加药,精准加药控制系统,分散式设备运维管理系统",
        },
        {
          name: "description",
          content:
            "小鲲智能技术（广州）有限公司是一家专业面向环保领域AIOT数智化技术服务的企业，围绕工艺运行智能化、业务流程信息化、系统管控精细化的研发制造为核心的高新技术企业。",
        },
      ],
    };
  },
  created() {
    scrollTo(0, 0);
  },
  mounted() {
    new WOW({ live: false }).init();
  },
  // components: {
  //   Footers,
  // },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  .title {
    width: 100%;
    font-size: 26px;
    text-align: center;
    background-color: #fff;
  }
  .title2 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #0099ba;
    padding: 0 50px;
  }
  .product_bigbox {
    width: 100%;
    height: auto;
    background-color: #f0f0f0;
    box-shadow: 0 0px 5px #f8f8f8 inset;
    padding: 55px 0 20px 0;
    box-sizing: border-box;
    .product_box {
      width: 100%;
      height: auto;
      background-color: #f0f0f0;
      margin: 0 auto;
      padding: 40px 120px 20px 120px;
      .card_li {
        transition: transform 0.5s;
        cursor: pointer;
        border: 0;
        overflow: hidden;
        .card-img-top {
          &:hover {
            transform: scale(1.05);
            transition: all 0.3s linear;
          }
        }
      }
      .card-body {
        padding: 10px;
        box-sizing: border-box;
        .card-title {
          text-align: center;
          margin: 5px 0 !important;
          font-size: 18px;
        }
      }
      .text-muted {
        font-size: 12px;
        font-weight: bold;
        color: #0099ba !important;
      }
    }
  }

  .cooperation_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px 120px 40px 120px;
    .cooperation_li {
      border: 1px solid #f8f8f8;
      transition: transform 0.5s;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px #ccc;
      }
    }
  }
  .problem_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px 120px 40px 120px;
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .title {
      font-size: 22px;
    }
    .product_bigbox {
      .product_box {
        padding: 0px 20px 0px 20px !important;
        .card-body {
          // padding: 0;
          .card-title {
            font-size: 16px;
          }
        }
      }
    }

    .cooperation_box,
    .problem_box {
      padding: 40px 20px 40px 20px !important;
    }
    .problem_box {
      .card-body {
        padding: 10px;
        height: 160px;
        box-sizing: border-box;
      }
      .card-title {
        font-size: 20px;
      }
      .card-text {
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1366px) {
    .product_bigbox {
      .product_box {
        padding: 0px 20px 0px 20px !important;
        .card-body {
          // padding: 0;
          .card-title {
            font-size: 16px;
          }
        }
      }
    }

    .cooperation_box,
    .problem_box {
      padding: 40px 50px 40px 50px !important;
    }
    .problem_box {
      .card-body {
        padding: 10px;
        height: 140px;
        box-sizing: border-box;
      }
      .card-title {
        font-size: 20px;
      }
      .card-text {
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 1366px) and (max-width: 1550px) {
    .product_bigbox {
      .product_box {
        padding: 0px 120px 0px 120px !important;
        .card-body {
          // padding: 0;
          .card-title {
            font-size: 16px;
          }
        }
      }
    }
    .problem_box {
      .card-body {
        padding: 10px;
        height: 120px;
        box-sizing: border-box;
      }
      .card-title {
        font-size: 20px;
      }
      .card-text {
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 1550px) and (max-width: 1920px) {
    .product_bigbox {
      .product_box {
        padding: 0px 120px 25px 120px !important;
        // .card-body {
        //   // padding: 0;
        // }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .product_bigbox {
      padding: 25px 0 20px 0;
      .product_box {
        padding: 0px 10px 0px 10px;
      }
    }

    .cooperation_box,
    .problem_box {
      padding: 40px 0 40px 0;
    }
  }
}
</style>